import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import { Grid, TextField, Typography, Box } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import { useDebounce } from 'use-debounce';
import * as _ from 'lodash';
import PilotProject from '../../data/pilot-project.json';

export default function PilotProjectPage() {
  const [years, setYears] = useState([]);
  const [hasResult, setHasResult] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [filteredPilotProject, setfilteredPilotProject] = useState({});
  const [value] = useDebounce(searchText, 1000);

  useEffect(() => {
    if (value.length > 0) {
      let _filteredPilotProject = {};
      let _hasResult = false;
      years.map((_year) => {
        const _filteredYear = _.filter(PilotProject[_year], (_pilotProject) => {
          return (
            _pilotProject.title.toLowerCase().includes(value.toLocaleLowerCase()) ||
            _pilotProject.description.toLowerCase().includes(value.toLowerCase())
          );
        });
        if (_filteredYear.length > 0) {
          _filteredPilotProject[_year] = _filteredYear;
          if (!_hasResult) _hasResult = true;
        }
        return false;
      });
      setHasResult(_hasResult);
      setfilteredPilotProject(_filteredPilotProject);
    } else {
      setHasResult(true);
      setfilteredPilotProject(PilotProject);
    }
    return () => {};
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    const _years = Object.keys(PilotProject);
    setYears(_years.sort((a, b) => b - a));
    setfilteredPilotProject(PilotProject);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      {
        <>
          <SEO lang='en' title='Pilot Project' />
          <SectionContainerLayoutWithFilter
            title='PILOT PROJECT'
            baseLink='/pilot-project'
            filters={years}
            isViewAll
            isDivider>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={6}>
                <TextField
                  placeholder='Search Pilot Project'
                  label='Search Pilot Project'
                  color='secondary'
                  variant='outlined'
                  fullWidth
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Grid>
              {hasResult ? (
                years.map((_year, index) => {
                  return filteredPilotProject[_year] ? (
                    <Grid key={index} item xs={12}>
                      <Typography gutterBottom variant='h6' className='text-uppercase'>
                        <Box my={3}>{_year}</Box>
                      </Typography>
                      <Grid container spacing={3}>
                        {filteredPilotProject[_year].map((_data, index) => (
                          <Grid key={index} item xs={12} sm={6} md={3}>
                            <CardImageTitleDescription
                              image={_data.image}
                              title={_data.title}
                              body={_data.description}
                              readMore={`/pilot-project/${_year}/${_data.url}`}
                              lines={6}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : null;
                })
              ) : (
                <Grid item xs={12}>
                  <Box m={8} p={{ xs: 0, sm: 8 }} fullWidth textAlign='center'>
                    <Typography color='textSecondary' variant='button'>
                      No Pilot Project Found
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </SectionContainerLayoutWithFilter>
        </>
      }
    </Layout>
  );
}
